<template>
  <div ref="dropdownTrigger" :class="{ relative: !fixed }">
    <slot name="trigger" :is-open="isOpen" :open="open" :close="menuClose">
      <div @click="open">Open</div>
    </slot>

    <transition
      enter-active-class="ease-out duration-300"
      enter-from-class="opacity-0 scale-y-0"
      enter-to-class="opacity-100 scale-y-100"
      leave-active-class="ease-in duration-100"
      leave-from-class="opacity-100 scale-y-100"
      leave-to-class="opacity-0 scale-y-0"
    >
      <div
        v-if="isOpen"
        class="z-50 transform transition-all"
        :class="[fixed ? 'fixed' : 'absolute', position, origin]"
        :style="alignStyle()"
        @click.stop
      >
        <slot name="content" :close="menuClose">
          <div class="p-2 bg-white shadow">
            <div
              v-for="index in 5"
              :key="`rikaz-dropdown-item-${index}`"
              @click="menuClose"
            >
              Item: {{ index }}
            </div>
          </div>
        </slot>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    closeOnChoose?: boolean
    position?: string
    align?: 'stretch' | 'start' | 'end' | 'center'
    origin?: string
    fixed?: boolean
  }>(),
  {
    closeOnChoose: true,
    position: 'mt-2',
    align: 'stretch',
    origin: 'origin-top',
    fixed: true
  }
)

const isOpen = ref(false)
const dropdownTrigger = ref<HTMLElement | null>(null)

onMounted(() => {
  document?.addEventListener('click', documentClose)
})

onBeforeUnmount(() => {
  document?.removeEventListener('click', documentClose)
})

function open() {
  isOpen.value = true
}

function close() {
  isOpen.value = false
}

function documentClose(e: Event) {
  if (dropdownTrigger.value) {
    if (
      dropdownTrigger.value.isSameNode(e.target as Node) ||
      dropdownTrigger.value.contains(e.target as Node)
    ) { return }
  }

  close()
}

function menuClose() {
  if (props.closeOnChoose) { close() }
}

function alignStyle() {
  if (!props.fixed) { return }
  if (!dropdownTrigger.value) { return }

  const rect = dropdownTrigger.value.getBoundingClientRect()

  switch (props.align) {
    case 'stretch':
      return `
          top: ${rect.bottom}px;
          left: ${rect.left}px;
          right: ${window.innerWidth - rect.right}px;
        `

    case 'end':
      if (document.dir == 'ltr') {
        return `
            top: ${rect.bottom}px;
            right: ${window.innerWidth - rect.right}px;
          `
      } else { return `top: ${rect.bottom}px; left: ${rect.left}px;` }

    case 'center':
      return `
          top: ${rect.bottom}px;
          left: ${rect.left + rect.width / 2}px;
          transform: translateX(-50%)
        `

    case 'start':
    default:
      return `top: ${rect.bottom}px;`
  }
}
</script>
